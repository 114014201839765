import React from 'react';
import labCover from '../images/lab-bg.jpg';
import { Link } from 'gatsby';

export default ()=>(
  <div className=" bg-cover bg-center flex items-center justify-center" style={{ minHeight: "85vh", backgroundImage: `url(${labCover})` }}>
    <div className="max-w-2xl mx-auto relative z-10 text-center text-white" >
      <h2 className=" font-bold text-5xl mr-8 ">
        DIAGNOSUR 
        <span className="font-light"> - Laboratorio de Análisis clínicos</span>
      </h2>
      <p className="text-xl mt-4">Apoyamos integralmente en el diagnóstico y pronóstico de problemas de salud.</p>
      <div className="mt-16 flex justify-between px-4 sm:px-0  sm:items-start lg:items-center flex-col sm:flex-row">
        <Link to="/servicios" className="d-bg-green lg:mr-6 uppercase p-3 text-white rounded-lg shadow ">Estudios disponibles <i className="notranslate fa fa-arrow-circle-right "></i> </Link>
        <Link to="/sucursales" className="mt-4 sm:mt-0 uppercase bg-white d-text-blue rounded shadow p-3">Encuentra tu sucursal <i className="fa fa-map-marker-alt"></i> </Link>
      </div>
    </div>
    {/* <div>
      <div className="rounded-full relative bg-blue-100 flex items-center justify-center" style={{ height: "550px", width: "550px" }}>
        <div className="big-white-border"></div>
        <img src={labCover} style={{ height: "480px", zIndex: "15" }} alt="Químico haciendo análisis en un microscopio" className="relative" />
      </div>
    </div> */}
  </div>
)