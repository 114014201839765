import React, { useState } from 'react';
import FooterNavigation from './FooterNavigation';
export default ()=>{
  const [formSubmited, setFormSubmited] = useState(false);
  const formSubmitedFunc = (ev)=>{
    ev.preventDefault();
    setFormSubmited(true);
  }
  return(
    <div className="d-bg-chiapas sm:mt-32 text-white text-center py-16">
      <div className="max-w-4xl mx-auto text-white">
        <div className="flex p-4 flex-wrap">
          <div className="md:w-1/3 w-full text-left">
            <p className=" font-bold text-4xl ">
              Atención y Contacto
              </p>
            <p className="mt-2">
              Queremos mejorar para tí y tu opinión es muy importante. Por favor escribe tus comentarios, dudas, quejas, propuestas o sugerencias para darles seguimiento y así brindarte una mejor experiencia con nuestro servicio.
              </p>
          </div>
          <div className="hidden sm:inline-block w-1/12"></div>
          <div className="flex-1 mt-6 sm:mt-0">
            {formSubmited && <p className="text-2xl">Tu mensaje ha sido enviado.</p>}
            {!formSubmited &&
              <form action="https://formspree.io/uriel.hdzc@gmail.com" method="POST" onSubmit={formSubmitedFunc}>
                <div className="form-control">
                  <label>Nombre</label>
                  <input type="text" />
                </div>
                <div className="form-control">
                  <label>Correo electrónico</label>
                  <input type="email" name="correo" />
                </div>
                <div className="form-control">
                  <label>Asunto</label>
                  <input type="text" name="asunto" />
                </div>
                <div className="form-control">
                  <label>Mensaje</label>
                  <textarea name="mensaje"></textarea>
                </div>
                <div className="mt-2 text-right">
                  <input type="submit" value="Enviar" className="btn mt-4" />
                </div>
              </form>
            }
          </div>

        </div>
        <div className="boder-solid border-t-2 border-white mt-16 pt-16 text-left">
          <FooterNavigation/>
        </div>
      </div>

    </div>
  )
}