import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Cover from '../components/Cover';
import Studies from '../components/Studies';
import Contact from '../components/Contact';
import chemicalFade from '../images/chemical.jpg'
const IndexPage = () => (
  <Layout>
    
    <Cover/>
    <div className="bg-white text-center py-16 px-8 sm:px-0">
      <p className="max-w-2xl uppercase mx-auto text-2xl font-bold"><i className="fa fa-clock mr-4 inline-block"></i>Horario de atención </p>
      <p className="text-2xl">De lunes a viernes de 7:00 a 20:00 y sábado de 7:00 a 14:00</p>
      
    </div>
    <Studies />
    <div className="my-32 max-w-6xl flex p-8 items-center mx-auto bg-blue-100 quality-cover bg-cover bg-center" style={{backgroundImage: `url(${chemicalFade})`}}>
      <div className="mt-20 text-left text-white pb-4">
        <p className="text-2xl font-bold uppercase">Calidad y Certificación</p>
        <p className="text-lg max-w-sm mt-2">Conoce más de nuestra política de calidad y las certificaciones con las que cuenta el laboratio.</p>
        <Link to="/certificaciones" className="btn mt-4 inline-block lg:mr-6 ">Ver más información <i className="fa fa-arrow-circle-right "></i> </Link>
      </div>
    </div>
    <Contact/>
  </Layout>
)

export default IndexPage
