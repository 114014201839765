import React from 'react';
import laboratory from '../images/laboratory.jpg';
import { graphql, useStaticQuery, Link }  from 'gatsby';
export default (props)=>{
  const data = useStaticQuery(graphql`
    {
      allCategoriesJson {
        nodes {
          name
          slug
        }
      }
    }
  `)
  const studies = data.allCategoriesJson.nodes;
  // const fullWidth = props.fullWidth || false;
  return(
    <div className={!props.fullWidth ? "sm:mt-32 mt-8 flex flex-col md:flex-row max-w-6xl mx-auto px-8 sm:px-0" : undefined}>
      {!props.fullWidth && <div className="sm:max-w-sm w-full sm:w-auto">
        <img src={laboratory} alt="Laboratorista realizando un estudio" className="rounded-lg overflow-hidden" />
        <p className=" font-bold text-4xl d-text-blue ">
          Conoce Nuestros <br /> servicios
        </p>
        <p className="mt-2">
          Realizamos toda una variedad de estudios de rutina, también perfíles, marcadores y pruebas especiales muy específicas que ayuden en el diagnóstico y control de numerosas enfermedades.
        </p>
      </div>}

      <div className={"flex-1 w-full mt-6 sm:mt-0 " + (!props.fullWidth && "sm:ml-16")}>
        <ul className="flex flex-wrap">
          {
            studies.map((study,index) => (
              
                <li className="p-8 shadow w-6/12 flex-shrink-0 " key={index}>
                <Link to={`/estudios/${study.slug}`} key={index} className="w-full inline-block flex flex-wrap">
                    <span className="flex-1 uppercase font-bold">{study.name}</span>
                    <i className="fa fa-arrow-circle-right ml-4 text-lg d-text-green inline-block"></i>
                  </Link>    
                </li>
              
            ))
          }
        </ul>
      </div>
    </div>
  );
}